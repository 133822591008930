import * as React from 'react'
import Layout from '../components/Layout'
import tw from 'twin.macro' // eslint-disable-line


const HolidaysPage = () => {

  return (
    <Layout>
      <div tw='py-7 bg-black min-h-screen'>
        <video tw='mx-auto' controls playsInline poster="/play.jpg">
          <source src='/adjmi-apparel-holiday-card.mp4' type="video/mp4" />
        </video>
      </div>

    </Layout>
  )
}

export default HolidaysPage
